#map {
  width: 100vw;
  height: 100vh;
  background-color: white;
  user-select: none;
}

.country {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.2;
}

.country:hover {
  opacity: .9;
}

.country:active {
  opacity: .9;
}

.country-label {
  fill: #006fab;
  font-size: 12px;
  font-weight: 700;
  text-anchor: middle;
  text-transform: uppercase;
}
